section.main {
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  img.logo {
    width: 15rem;
  }

  h1, h2 {
    text-align: center;
  }

  .bots {
    width: 100%;

    display: flex;

    flex-direction: row;

    justify-content: space-evenly;

    a {
      text-decoration: none;

      color: #000;
    }

    h1 {
      font-family: "Major Mono Display", sans-serif;
    }

    img {
      height: 18rem;
    }
  }
}
