.video {
  position: fixed;

  width: 50%;
  height: 100%;
  
  top: 0;
  right: 0;
  bottom: 0;
  
  background-color: #ffffff;
  border-left: 2px solid #e0e0e0;

  video {
    object-fit: cover;

    width: 100%;
    height: 100%;
  }

  .spinner {
    z-index: -1;

    position: absolute;

    margin: auto;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
