.names,
.names div {
  display: flex;

  flex-direction: row;

  align-items: flex-end;
  justify-content: space-between;

  font-family: "Major Mono Display", sans-serif;

  h1 {
    margin: 0;
  }

  a {
    color: darkgray;

    text-decoration: none;

    padding: 0 0.125rem;
    margin-left: 0.75rem;

    border-bottom: 3px solid darkgray;
  }
}

.subtitle {
  display: flex;

  flex-direction: row;

  justify-content: space-between;
  align-items: flex-end;

  h2 {
    font-size: 1.25rem;

    font-weight: normal;

    padding: unset 0.125rem;
  }

  a {
    color: darkgray;

    text-decoration: none;

    padding: 0 0.125rem;
    border-bottom: 3px solid darkgray;
  }
}
