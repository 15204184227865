div.spinner {
  width: 3rem;
  height: 3rem;

  margin: 3rem;

  border: 0.25rem solid transparent;

  border-radius: 50%;

  animation: linear infinite 0.8s spin;
  animation-delay: 0.5s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    border-top: 0.25rem solid #000000;
  }
  100% {
    transform: rotate(360deg);
    border-top: 0.25rem solid #000000;
  }
}
