@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Montserrat&display=swap");

@import "./bot.scss";
@import "./main.scss";
@import "./video.scss";
@import "./spinner.scss";
@import "./titles.scss";
@import "./transitions.scss";

html {
  background-color: #ffffff;

  background-size: 10vmin 10vmin;
  background-image: linear-gradient(#e0e0e0 1.5px, transparent 1.5px),
    linear-gradient(to right, #e0e0e0 1.5px, transparent 1.5px);

  background-position: 50vw;

  font: 1.5rem "Montserrat", sans-serif;
}
