section.bot {
  width: calc(50% - 4rem);
  height: calc(100% - 4rem);

  padding: 2rem;

  * {
    transition: all 0.2s;
  }

  ol {
    padding-left: 0;

    li {
      display: flex;
      flex-direction: row;

      align-items: center;

      width: fit-content;

      cursor: pointer;

      &:hover img {
        transform: scale(1.2);
      }
    }

    img {
      width: 3.5rem;
    }
  }

  table {
    background-color: white;

    border-collapse: collapse;

    font-size: 0.75em;
    text-align: center;

    margin: auto;

    td {
      border: 2px solid #e0e0e0;

      width: 5rem;
      height: 5rem;

      &.unavailable {
        filter: grayscale(100%);
      }
    }

    tbody td {
      cursor: pointer;
    }

    tbody td:hover:not(.unavailable) img {
      transform: scale(1.2);
    }

    img {
      width: 3rem;
    }
  }
}
